import { useLazyQuery, useQuery } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useContext, useEffect } from 'react';
import AppContext from '~contexts/AppContext';
import { GET_PAYMENT_STATUS } from '~graphql/queries';
import getSearchParams from '~utils/getSearchParam';
// import { PAYMENT_INFO_KEY } from '~utils/localstorageHelper';
import { Routes } from '../../types/routes';
import { dynamicNavigate } from '../../utils/routeHelper';
import { GET_TOKEN } from '../../graphql/queries';
import { setLocalStorageValue } from '../../utils/localstorageHelper';
import { localstorageKeys } from '../../types';
// import { setLocalStorageValue } from '../../utils/localstorageHelper';
// import { localstorageKeys } from '../../types/localstorageTypes';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const authentication = () => {
  const {
    actions: { resetItems }, setCurrentUser,
  } = useContext(AppContext);
  const styles = useStyles();
  const orderId = getSearchParams('orderId');
  const customerId = getSearchParams('customerId');

  const [
    getToken,
    {
      data: tokenData
    },
  ] = useLazyQuery(GET_TOKEN, {
    onCompleted: data => {
      // Trigger the second query after the first one completes successfully
      if (data && data.getToken) {
        getPaymentStatus({
          variables: { orderId: Number(orderId) },
        });
      }
    },
  });

  const [getPaymentStatus, { data: paymentStatus, loading: paymentLoading, error: paymentError }] = useLazyQuery(
    GET_PAYMENT_STATUS,
    {
      context: {
        headers: {
          authorization: `Bearer ${tokenData?.getToken?.token}`,
        },
      },
    }
  );

  // Trigger the token fetching on component mount
  useEffect(() => {
    getToken({
      variables: { id: Number(customerId) },
    });
  }, []);

  // Handle payment status after GET_PAYMENT_STATUS completes
  useEffect(() => {
    if (!paymentLoading && paymentError) {
      handlePaymentFailure();
    } else if (
      !paymentLoading &&
      !paymentError &&
      paymentStatus
    ) {
      const {
        orderGetPaymentStatus: { paymentConfirmation },
      } = paymentStatus;

      if (paymentConfirmation === 'Completed') {
        handlePaymentComplete();
      } else {
        handlePaymentFailure();
      }
    }
  }, [paymentStatus, paymentError]);

  useEffect(() => {
    const token = tokenData?.getToken?.token;
    setCurrentUser({ accessToken: token, customerId: Number(customerId) });
    if (token && customerId) {
      setLocalStorageValue(localstorageKeys.token, {
        token,
        [localstorageKeys.timeStamp]: Date.now(),
      });
      setLocalStorageValue(localstorageKeys.customerId, {
        id: Number(customerId),
        [localstorageKeys.timeStamp]: Date.now(),
      });
    }
  }, [tokenData]);

  const handlePaymentComplete = () => {
    resetItems();
    dynamicNavigate(
      `${Routes.ORDERSTATUS}?orderId=${orderId}`
    );
  };

  const handlePaymentFailure = () => {
    dynamicNavigate(`${Routes.PAYMENT_FAILED}`);
  };

  return (
    <div className={styles.container}>
      <CircularProgress color="primary" />
    </div>
  );
};

export default authentication;
